import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Login = ({ setIsAuthenticated }) => {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === 'vangoghScanSystem') {
            localStorage.setItem('isAuthenticated', 'true');
            setIsAuthenticated(true);
            navigate('/scan');
        } else {
            alert('Incorrect password');
        }
    };
    return (
        <LoginWrapper>
            <form onSubmit={handleSubmit}>
                <label htmlFor="password">Enter Password:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Login</button>
            </form>
        </LoginWrapper>
    );
};

export default Login;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgba(9, 10, 19, 1);
  color: white;
  font-size: 16px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  input {
    font-size: 16px;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }

  button {
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
  }
`;
