import React, {useEffect, useState} from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import ScanPage from './pages/ScanPage';

const Router = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const auth = localStorage.getItem('isAuthenticated') === 'true';
        setIsAuthenticated(auth);
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Navigate to={isAuthenticated ? "/scan" : "/login"} />} />
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/scan" element={<PrivateRoute isAuthenticated={isAuthenticated}><ScanPage /></PrivateRoute>} />
        </Routes>
    );
};

const PrivateRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default Router;
