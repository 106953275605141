import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import successIcon from '../assets/success.svg';
import errorIcon from '../assets/error.svg';

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return {
        date: `${day}.${month}.${year}`,
        time: ` ${hours}:${minutes}`
    }
}

const ScanPage = () => {
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({
        isSuccess: false,
        message: '',
        date: null
    });
    const lastScanTimeRef = useRef(0);

    const handleScan = async (result, error) => {
        const now = Date.now();

        console.log('LAST SCAN:', lastScanTimeRef.current);
        console.log('NOW SCAN:', now);

        if (result && now - lastScanTimeRef.current > 2000) { // 2-second delay
            lastScanTimeRef.current = now; // Update ref instead of state
            setLoading(true);
            setNotification({
                isSuccess: false,
                message: '',
                date: null
            });

            try {
                const response = await axios.get(`https://tickets.vangogh-kiallitas.hu/validate?serialNumber=${result.text}&auth=vangoghadmin`);
                setNotification({
                    isSuccess: true,
                    message: response.data.message,
                    date: null
                });
            } catch (error) {
                if (error.response.data.date) {
                    setNotification({
                        isSuccess: false,
                        message: error.response.data.message,
                        date: formatDate(error.response.data.date)
                    });
                } else {
                    setNotification({
                        isSuccess: false,
                        message: error.response.data.message,
                        date: null
                    });
                }

            } finally {
                setLoading(false);
            }
        }

        if (error) {
            console.info(error);
        }
    };

    useEffect(() => {
        if (notification.message) {
            const timer = setTimeout(() => {
                setNotification({
                    isSuccess: false,
                    message: ''
                });
            }, 2000); // Adjusted to match notification duration
            return () => clearTimeout(timer);
        }
    }, [notification]);

    return (
        <Wrapper>
            {loading && (
                <Overlay>
                    <Spinner />
                </Overlay>
            )}
            <QrScanner
                delay={2000} // Ensure delay is set correctly
                onResult={handleScan}
                constraints={{ facingMode: 'environment' }}
            />
            {notification.message &&
                <Notification className={notification.isSuccess ? 'success' : 'error'}>
                    <LineWrapper>
                        <img src={notification.isSuccess ? successIcon : errorIcon} alt="icon"/>
                        {notification.message}
                    </LineWrapper>
                    {notification.date && (<DateWrapper><span>Date: </span> {notification.date.date}</DateWrapper>)}
                    {notification.date && (<DateWrapper><span>Time: </span>{notification.date.time}</DateWrapper>)}
                </Notification>}
        </Wrapper>
    );
};

export default ScanPage;

const LineWrapper = styled.div`
  display: flex; 
  align-items: center;
  gap: 6px;
  img {
    width: 24px;
    height: 24px;
  }
`
const DateWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  span {
    font-weight: 400;
    rgba(255, 255, 255, 0.6)
  }
`
const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: rgba(9, 10, 19, 1);
  position: relative;
  padding: 16px;
`;

const QrScanner = styled(QrReader)`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  height: 300px;
  video {
    object-fit: cover;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Spinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Notification = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 11%;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  padding: 15px;
  &.success {
    background: rgba(74, 204, 13, 0.5);
  }
  &.error {
    background: rgba(255, 61, 0, 0.5);
  }
`;
