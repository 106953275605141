import React from 'react';
import Router from "./Router";
import styled from "styled-components";
import {BrowserRouter} from "react-router-dom";
import {GlobalStyles} from "./globalStyles";


const App = () => {
    return (
        <>
            <GlobalStyles/>
            <Wrapper>
                <BrowserRouter>
                    <Router/>
                </BrowserRouter>
            </Wrapper>
        </>
    );
};

export default App;

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
`